<template>
  <div>
    <v-container>
      <v-form
        v-model="isFormValid"
        @submit.prevent="submit"
      >
        <!-- STATUS -->
        <v-select
          v-if="action === 'edit'"
          id="status"
          :rules="rules.required"
          v-model="document.status"
          :items="statusTypes"
          label="Status"
          required
          outlined
        ></v-select>

        <!-- TYPE -->
        <v-combobox
          v-model="document.type"
          :items="documentTypes"
          label="Document Type"
          required
          outlined
        ></v-combobox>

        <!-- TITLE -->
        <v-text-field
          id="doctitle"
          type="text"
          :rules="rules.required"
          v-model="document.title"
          label="Title"
          hint="Title must be compliant with URL routes profile/legal/xxx"
          required
          outlined
          validate-on-blur
        ></v-text-field>

        <!-- LOCALE -->
        <v-select
          id="locale"
          :rules="rules.required"
          v-model="document.locale"
          :items="$i18n.availableLocales"
          label="Locale"
          required
          outlined
        ></v-select>

        <!-- CONTENT -->
        <v-textarea
          id="content"
          :rules="rules.textarea"
          v-model="document.content"
          label="Content"
          required
          outlined
        ></v-textarea>

        <!-- DATE OF START -->
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="document.dateStart"
              :rules="rules.required"
              label="Date Start"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              validate-on-blur
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="document.dateStart"
            max="2030-01-01"
            :min="new Date().toISOString().substr(0, 10)"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-form>
    </v-container>

    <v-app-bar
      dense flat
      app
      bottom
      color="background"
    >
      <v-row>
        <v-col cols="2">
          <v-btn
            block
            color="primary"
            @click="back"
            class="black--text"
          >
            <v-icon>
              navigate_before
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
        </v-col>
        <v-col cols="9">
          <v-btn
            block
            color="primary"
            @click="submit"
            :disabled="!isFormValid"
            class="black--text"
          >
          {{ action }} Document
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required,
  textarea,
} from '@/utils/validators';

export default {
  props: {
    documentAsProp: {
      type: Object,
      default: () => ({
        status: 'Draft',
        type: '',
        title: '',
        locale: '',
        content: '',
        dateStart: '',
      }),
    },
    action: {
      type: String,
    },
  },
  data() {
    return {
      document: { ...this.documentAsProp },
      documentTypes: ['Private', 'Public'],
      statusTypes: ['Draft', 'Active'],
      isFormValid: false,
      menu: false,
      rules: {
        required,
        textarea,
      },
    };
  },
  /* eslint-disable no-return-assign */
  watch: {
    menu(val) {
      val && setTimeout(() => (
        this.$refs.picker.activePicker = 'YEAR'
      ));
    },
  },
  methods: {
    ...mapActions('documents', [
      'create',
      'edit',
    ]),
    async submit() {
      try {
        // action === 'create' || 'update'
        // this.create || this.update
        this[this.action](this.document);
        this.$emit('close-modal');
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    back() {
      this.$router.go(-1);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
